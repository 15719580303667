.text-gray-400 {
  color: rgb(156 163 175);
}
.empty-main {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  .EmptyDatadiv {
    height: calc(93% - 90.5rem);
  }
}
