@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  font-family: "Inter", sans-serif;
}
@layer utilities {
  ::-webkit-scrollbar {
    width: 0.15rem;
  }
  ::-webkit-scrollbar-track {
    background-color: #F8F8F8;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    outline: none;
    border-radius: 0.275rem;
    width: 0.2rem;
  }
  
  ::-webkit-scrollbar:horizontal {
    height: 0.375rem;
  }
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.cssUseOnly::-webkit-scrollbar:horizontal {
  height: 0.15rem !important;
}
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.projectViewSlide .slide2 .slick-slide {
  width: 4.875rem !important;
  height: 3rem !important;
  margin: 0 0.75rem 0.75rem 0;
}
.projectViewSlide .slide2 .slick-slide.slick-current .content {
  background: #fff;
  box-shadow: unset;
}
.projectViewSlide .slide1 .slick-slide{
  transition: transform 0.5s ease;
}
.projectViewSlide .slide1 .slick-slide:nth-child(odd) {
  transform: translateX(100%);
}

.projectViewSlide .slide1 .slick-slide:nth-child(even) {
  transform: translateX(-100%);
}



.bounce,
.clienterror {
  animation: bounceIn 2s infinite 2s;
}
@keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.constant-tilt-shake {
  animation: tilt-shaking 0.3s infinite;
}
@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.call {
  animation: slideIn 1s ease-out;
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.pimg{
  animation: zoom-out 3s linear infinite;
}
@keyframes zoom-out {
  100% {
    width: 100%;
  }
}
.suply{
  width: 100%;
  overflow: hidden;
  animation: shrink 1s ease-out;
}
@keyframes shrink {
  from {
    width: 7rem;
  }
  to {
    width: 100%;
  }
}
.profile-content .name{
  animation: size 1s ease-out;
}
.profile-content .desig{
  animation: size1 1s ease-out;
}
.profile-content .email{
  animation: size2 1s ease-out;
}
@keyframes size {
  from {
    font-size: 0px;
  }
  to {
    font-size: 16px;
  }
}
@keyframes size1 {
  from {
    font-size: 0px;
  }
  to {
    font-size: 14px;
  }
}
@keyframes size2 {
  from {
    font-size: 0px;
  }
  to {
    font-size: 12px;
  }
}

.calling{
  width: 100%;
  overflow: hidden;
  animation: shrink1 1s ease-out;
}
@keyframes shrink1 {
  from {
    width: 7rem;
  }
  to {
    width: 100%;
  }
}
.calling-content .call-text{
  animation: size3 1s ease-out;
}
.calling-content .call-number{
  animation: size4 1s ease-out;
}
@keyframes size3 {
  from {
    font-size: 0px;
  }
  to {
    font-size: 14px;
  }
}
@keyframes size4 {
  from {
    font-size: 0px;
  }
  to {
    font-size: 18px;
  }
}

#header-bar {
  display: none;
}
#proxy-renderer {
  height: 100%;
  width: 100%;
}
#msdoc-renderer {
  height: 100%;
}
.addfilesDocViewr #react-doc-viewer{
  overflow: auto;
}
#header-bar {
  display: none;
}
#proxy-renderer {
  height: 100%;
  width: 100%;
  overflow: auto;
}
#msdoc-renderer {
  height: 100%;
}
#pdf-controls{
  box-shadow: unset;
  background: #FFF;
  border-bottom: 1px solid #D9D9D9;
}