.loader-list {
  max-width: 100% !important;
}
.hide {
  display: none;
}
.text-gray-400 {
  color: rgb(156 163 175);
}
.text-blue-500 {
  color: rgb(59 130 246);
}
.text-red-500 {
  color: rgb(239 68 68);
}
/* .slick-prev,
.slick-next {
  height: 3rem !important;
  width: 3rem !important;
}
.slick-prev:before,
.slick-next:before {
  font-size: 32px !important;
  color: #646464 !important;
} */
.slick-current .current-identity img {
  border: 1px solid #d9d9d9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
}
.animate-pulse-2{
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }
}
.animate-pulse-3{
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }
}
.small-datepicker .react-datepicker__header{
  padding-top: 10px;
}
.small-datepicker .react-datepicker__navigation-icon:before{
  top:6px;
  height: 7px;
  width: 7px;
}
.small-datepicker .react-datepicker__current-month{
  font-size: 0.875rem;
}
.small-datepicker .react-datepicker__month-container{
  width: 11.75rem;

}
.small-datepicker .react-datepicker__day-name{
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.3rem;
  font-size: 12px;
}
.small-datepicker .react-datepicker__day{
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.3rem;
  font-size: 12px;
}
/* .actiondropdown .css-1qpkzkj-ValueContainer{
  display: inline-block;
} */
.unselectable {
    /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */
    /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
    -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
    -webkit-user-select: none;
    -ms-user-select: none; /* From IE10 only */
    user-select: none; /* Not valid CSS yet, as of July 2012 */

    -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
    /* user-drag: none; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}